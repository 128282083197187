<template>
  <main class="main news">
    <page-slider
      v-if="articlesList[0].acf.banner_group.slides"
      :slides="articlesList[0].acf.banner_group.slides"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="my-5">
      <!-- <section-title v-text="pageData.acf.title" v-if="pageData.acf.title">news</section-title> -->
    </b-container>

    <!-- <b-container fluid="xl" class="px-0">
      <form-category-market
        :firstFilter="filters.news_category"
        :secondFilter="filters.market_category"
        :allowOrderBy="false"
        :allowPagination="false"
        @chooseFirstFilter="chooseFirstFilter"
        @chooseSecondFilter="chooseSecondFilter"
      />
    </b-container> -->

    <b-container fluid="xl" class="px-0">
      <articles-list-item v-for="(item, index) in articlesList" :article="item">
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="bg-silver" />
        </b-container>
      </articles-list-item>

      <b-container
        fluid="xl"
        class="px-0 my-3 text-center"
        v-if="see_more && page < filters.total_pages"
      >
        <a @click="seeMore" class="px-0 my-5 text-uppercase more">
          <span>see more</span>
          <br />
          <img src="/images/icons/common/icon-down-arrow.svg" alt="" />
        </a>
      </b-container>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import ArticlesListItem from "../components/SearchListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    ArticlesListItem,
    Divider,
  },
  data() {
    return {
      isLoading: true,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "news",
      pageData: [],
      filterOptions: {
        postType: "news",
        categoryTaxonomy: "news_category",
        allowOrderBy: true,
        allowPagination: true,
      },
      articlesList: [],
      case_study_category: "",
      market_category: "",
      per_page: 5,
      page: 1,
      see_more: true,
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios
        // .get(this.backendUrl + "pages?slug=" + this.currentPageSlug)
        .get(
          `case-studies?case_study_category=${this.case_study_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`
        )
        .then((response) => {
          let length = response.data.length - 1;
          this.filters = response.data[length];
          console.log("filters", this.filters);

          let object = response.data;
          object.pop();
          this.articlesList = object;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseFirstFilter(item) {
      this.page = 1;
      this.news_category = item;
      this.getNews();
    },
    chooseSecondFilter(item) {
      this.page = 1;
      this.market_category = item;
      this.getNews();
    },
    seeMore() {
      this.page++;
      axios
        .get(
          `case-studies?case_study_category=${this.case_study_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`
        )
        .then((response) => {
          console.log(response);
          let object = response.data;

          if (object.length) {
            this.articlesList.push(...object);
          } else {
            this.see_more = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
